import React from 'react';
import TpButton from '../ui/Button';

const Ideas = () => {
  return (
    <section className="container mx-auto mt-12 sm:mt-14 md:mt-20 sm:pb-24 lg:pb-[90px]">
      <div className="w-full pb-3 lg:pb-12 sm:w-[90%] xl:max-w-[1425px] bg-gray-darker sm:bg-pic sm:bg-center sm:bg-auto sm:bg-no-repeat mx-auto sm:rounded-[32px] text-white text-center">
        <h2 className="w-[90%] sm:w-[496px] mx-auto font-semibold text-lg leading-[21px] sm:text-[40px] sm:leading-[47px] pt-11 sm:pt-32 mb-4">
          Ready to Build Something Amazing
          <span className="text-primary">?</span>
        </h2>
        <p className="w-[80%] sm:w-[380px] mx-auto mb-8 sm:mb-16 font-semibold text-xs leading-6 sm:text-lg sm:leading-8">
          We value long-term business relationships, and we’re guessing you do
          too.
        </p>

        <div className="text-center sm:mb-[123px]">
          <TpButton
            className="w-[70%] sm:w-[290px] mx-auto py-4 px-8 sm:px-14 mb-4 text-base leading-5 bg-primary rounded font-semibold text-white transition hover:bg-white hover:text-primary hover:border-white"
            text="Get Free Consultation"
            action="/contact"
          />
        </div>
      </div>
    </section>
  );
};

export default Ideas;
